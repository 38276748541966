<template>
  <div class="view">
    <div class="list">
      <div class="item" v-for="item in list" :key="item.id"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      type: 1,
      list: [
        {
          id: 1,
          phone: '15542313232',
          price: 100,
          time: '2023.09.21-12:45:56',
          status: 1
        },
        {
          id: 2,
          phone: '15542313232',
          price: 100,
          time: '2023.09.21-12:45:56',
          status: 0
        },
        {
          id: 3,
          phone: '15542313232',
          price: 100,
          time: '2023.09.21-12:45:56',
          status: 2
        }
      ]
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.type = this.$route.query.type
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.view {
  min-height: 100vh;
  background-color: #f6f6f8;

  .list {
    .item {
    }
  }
}
</style>
